import React, { Component } from "react"
import { Carousel, CarouselItem, CarouselControl } from "reactstrap"

class Slider extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  attachments = this.props.images

  onExiting() {
    this.animating = true
  }

  onExited() {
    this.animating = false
  }

  next() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === this.attachments.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous() {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? this.attachments.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex(newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    const slides = this.attachments.map((item, key) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={key}
        >
          <img
            src={item}
            // className="d-block img-fluid"
            // style={{
            //   display: "block",
            //   minWidth: "100%",
            //   width: "100%",
            //   minHeight: "700px",
            //   maxHeight: "700px",
            //   height: "100%",
            //   borderRadius: "20px",
            // }}
            className="img-fluid mx-auto d-block"
            style={{
              // display: "block",
              // minWidth: "100%",
              // width: "100%",
              // width: "620px",
              //minHeight: "700px",
              height: "580px",
              borderRadius: "20px",

              borderRadius: "20px",
            }}
            alt=""
          />
        </CarouselItem>
      )
    })

    return (
      <React.Fragment>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
      </React.Fragment>
    )
  }
}

export default Slider
