import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import Loader from "../../components/Common/Loader"
import { getEvents } from "../../store/events/actions"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "reactstrap"
import EventCard from "./EventCards2"
import NoData from "pages/Utility/NoData"
import { apiError } from "../../store/events/actions"
import SweetAlert from "react-bootstrap-sweetalert"

const BasicTable = () => {
  const dispatch = useDispatch()
  const { data, loading, error } = useSelector(state => ({
    data: state.Events.data,
    loading: state.Events.loading,
    error: state.Events.error,
  }))

  console.log(error)

  useEffect(() => {
    dispatch(getEvents())
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>My Events</title>
      </MetaTags>

      {error ? (
        <SweetAlert
          error
          title={"Error"}
          onConfirm={() => {
            dispatch(apiError(""))
          }}
        >
          {error}
        </SweetAlert>
      ) : null}

      <Row>
        <Col sm={12}>
          {data?.length > 0 && !loading ? (
            data
              .sort((a, b) => {
                const currentDate = new Date() // Get the current date

                // Convert date strings to Date objects for comparison
                const dateA = new Date(`${a.eventDate} ${a.startTime}`)
                const dateB = new Date(`${b.eventDate} ${b.startTime}`)

                if (dateA < currentDate && dateB < currentDate) {
                  // If both dates are before the current date, sort them normally
                  if (dateA < dateB) {
                    return -1
                  } else if (dateA > dateB) {
                    return 1
                  } else {
                    return 0
                  }
                } else if (dateA < currentDate) {
                  // If only the first date is before the current date, move it to the end
                  return 1
                } else if (dateB < currentDate) {
                  // If only the second date is before the current date, move it to the end
                  return -1
                } else {
                  // Both dates are after the current date, sort them normally
                  if (dateA < dateB) {
                    return -1
                  } else if (dateA > dateB) {
                    return 1
                  } else {
                    return 0
                  }
                }
              })
              .map((event, index) => {
                return (
                  <EventCard
                    key={index}
                    id={event?.id}
                    title={event?.title}
                    attachments={event?.attachments}
                    status={event?.status}
                    requiredSeats={event?.requiredSeats}
                    requiredTime={event?.requiredTime}
                    description={event?.description}
                    startTime={event?.startTime}
                    packages={event?.packages}
                    eventDate={event.eventDate}
                    eventLocation={event.eventLocation}
                    data={event}
                  />
                )
              })
          ) : (
            <>{!loading ? <NoData /> : ""}</>
          )}
        </Col>
      </Row>

      {loading && <Loader />}
    </React.Fragment>
  )
}

export default BasicTable
