import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  FormFeedback,
  FormGroup,
} from "reactstrap"
import category from "common/category"
import GooglePlacesAutoComplete from "../../../components/Common/GooglePlacesAutoComplete"
// Formik import * as Yup from "yup"
import { Formik } from "formik"
// action
import { registerUser, apiError } from "../../../store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import Select from "react-select"
// import images
import profileImg from "../../../assets/images/profile-img.png"
import logoImg from "../../../assets/images/logo-light.png"

import DropAttachments from "components/Common/DropAttachments"
import Error from "components/Common/Error"
import * as Yup from "yup"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const validationSchema = Yup.object({
  email: Yup.string().required("Please Enter Your Email"),
  number: Yup.string().required("Please Enter Your Number"),
  name: Yup.string().required("Please Enter Your Name"),
  bio: Yup.string().required("Please Enter Your Bio/Description"),
  location: Yup.object().required("Location must required"),
  password: Yup.string().required("Please Enter Your Password"),
  category: Yup.string().required("Select category"),
  prevAttachments: Yup.array()
    .min(1, "Atleast one attachment required")
    .required("Previous attachments required"),
})

const EvenetManager = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  // lightbox states
  const [isFits, setisFits] = useState(false)
  const [index, setIndex] = useState("")
  const [passwordType, setPasswordType] = useState("password")

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register Eevent Manager</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <Formik
        initialValues={{
          email: "",
          img: {},
          name: "",
          number: "",
          password: "",
          location: "",
          bio: "",
          prevAttachments: [],
          years: 0,
          // months: 0,
          category: "",
          isAppUser: false,
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          const user = {
            email: values.email,
            img: values.img,
            name: values.name,
            number: values.number,
            password: values.password,
            location: values.location,
            bio: values.bio,
            role: "eventManager",
            prevAttachments: values.prevAttachments,
            category: values.category,
            experience: {
              years: values.years,
              months: 0,
            },
            status: "pending",
          }

          dispatch(
            registerUser({ user: user, history, isAppUser: values.isAppUser })
          )
        }}
      >
        {({
          errors,
          setFieldValue,
          values,
          handleBlur,
          handleChange,
          touched,
          handleSubmit,
        }) => {
          function setLocation(location) {
            setFieldValue("location", location)
          }

          function removeImage(i) {
            setFieldValue(
              "prevAttachments",
              values.prevAttachments.filter((f, index) => index !== i)
            )
          }
          function handleSelectedFiles(files) {
            setFieldValue("prevAttachments", [
              ...values.prevAttachments,
              ...files,
            ])
          }

          return (
            <div className="account-pages my-5 pt-sm-5">
              {isFits ? (
                <Lightbox
                  mainSrc={values?.prevAttachments[index].preview}
                  enableZoom={false}
                  onCloseRequest={() => {
                    setisFits(!isFits)
                  }}
                />
              ) : null}
              <Container>
                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={6}>
                    <Card className="overflow-hidden">
                      <div className="bg-primary bg-soft">
                        <Row>
                          <Col className="col-7">
                            <div className="text-primary p-4">
                              <h5 className="text-primary">
                                Register Event Manager
                              </h5>
                              <p>Register account as an event manager </p>
                            </div>
                          </Col>
                          <Col className="col-5 align-self-end">
                            <img
                              src={profileImg}
                              alt=""
                              className="img-fluid"
                            />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <div>
                          <Link to="/">
                            <div className="avatar-md profile-user-wid mb-4">
                              <span className="avatar-title rounded-circle bg-light">
                                <img
                                  src={logoImg}
                                  alt="IGP"
                                  className="rounded-circle"
                                  height="34"
                                />
                              </span>
                            </div>
                          </Link>
                        </div>
                        <div className="p-2">
                          {user && user ? (
                            <Alert color="success">
                              Register User Successfully
                            </Alert>
                          ) : null}

                          {registrationError && registrationError ? (
                            <Alert color="danger">{registrationError}</Alert>
                          ) : null}

                          <div className="mb-3">
                            <Label className="form-label">Name</Label>
                            <Input
                              name="name"
                              type="text"
                              placeholder="Enter Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name || ""}
                              invalid={
                                touched.name && errors.name ? true : false
                              }
                            />
                            {touched.name && errors.name ? (
                              <FormFeedback type="invalid">
                                {errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Profile Image</Label>
                            <Input
                              id="img"
                              name="img"
                              className="form-control"
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={e => {
                                setFieldValue("img", e.target.files[0])
                              }}
                            />
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email || ""}
                              invalid={
                                touched.email && errors.email ? true : false
                              }
                            />
                            {touched.email && errors.email ? (
                              <FormFeedback type="invalid">
                                {errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Number</Label>
                            <Input
                              name="number"
                              type="text"
                              placeholder="Enter Phone Number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.number || ""}
                              invalid={
                                touched.number && errors.number ? true : false
                              }
                            />
                            {touched.number && errors.number ? (
                              <FormFeedback type="invalid">
                                {errors.number}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">
                              Bio/Description
                            </Label>
                            <Input
                              name="bio"
                              type="textarea"
                              rows="5"
                              placeholder="Enter bio / Description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.bio || ""}
                              invalid={touched.bio && errors.bio ? true : false}
                            />
                            {touched.bio && errors.bio ? (
                              <Error text={errors.bio} />
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Location</Label>
                            <GooglePlacesAutoComplete
                              setLocation={setLocation}
                            />
                            {errors.location && touched.location ? (
                              <Error text={errors.location} />
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label htmlFor="horizontal-day-Input">
                              Category
                            </Label>
                            <Select
                              options={category}
                              getOptionLabel={option => (
                                <div className="text-capitalize">
                                  {option.label}
                                </div>
                              )}
                              getOptionValue={option => option}
                              classNamePrefix="select2-selection"
                              onChange={e => {
                                setFieldValue("category", e.label)
                              }}
                            />
                            {touched.category && errors.category ? (
                              <Error text={errors.category} />
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label>Experience</Label> {/* <div> */}
                            <small>(years)</small>
                            {/* </div> */}
                            <Input
                              type="number"
                              className="form-control mt-1"
                              placeholder="Enter Year"
                              name="years"
                              min={0}
                              onChange={handleChange}
                              value={values.years}
                              onBlur={handleBlur}
                            />
                          </div>

                          {/* <div className="mb-3">
                            <small>(month's)</small>
                            <Input
                              type="number"
                              className="form-control mt-1"
                              placeholder="Enter Month"
                              name="months"
                              min={0}
                              max={12}
                              onChange={handleChange}
                              value={values.months}
                              onBlur={handleBlur}
                            />
                          </div> */}

                          <div className="mb-3">
                            <Label className="form-label">Previous Work</Label>
                            {values?.prevAttachments?.length < 5 ? (
                              <DropAttachments
                                handleDropFiles={handleSelectedFiles}
                              />
                            ) : null}
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {values?.prevAttachments.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete m-3"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                              onClick={() => {
                                                setisFits(true)
                                                setIndex(i)
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Col>
                                          <Col
                                            style={{ wordBreak: "break-word" }}
                                          >
                                            <div
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </div>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                        </Row>
                                        <i
                                          className="mdi mdi-delete text-danger h3 mx-4"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => removeImage(i)}
                                        ></i>
                                      </div>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                            {errors.prevAttachments &&
                            touched.prevAttachments ? (
                              <p className="text-danger my-2">
                                {errors.prevAttachments}
                              </p>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <div
                              className="d-flex align-items-center justify-content-between gap-3"
                              style={{
                                border: "1px solid #ced4da",
                                borderRadius: ".25rem",
                                paddingRight: "15px",
                              }}
                            >
                              <Input
                                name="password"
                                type={passwordType}
                                placeholder="Enter Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password || ""}
                                style={{
                                  border: "none",
                                }}
                                // invalid={
                                //   touched.password && errors.password
                                //     ? true
                                //     : false
                                // }
                              />
                              <div
                                onClick={() => {
                                  passwordType == "password"
                                    ? setPasswordType("text")
                                    : setPasswordType("password")
                                }}
                              >
                                {passwordType == "password" ? (
                                  <i className="mdi mdi-eye mx-2 fs-5"></i>
                                ) : (
                                  <i className="mdi mdi-eye-off mx-2 fs-5"></i>
                                )}
                              </div>
                            </div>
                            {touched.password && errors.password ? (
                              // <FormFeedback type="invalid">
                              <p className="font-size-12 text-danger">
                                {" "}
                                {errors.password}
                              </p>
                            ) : // </FormFeedback>
                            null}
                          </div>

                          <FormGroup check={values.isAppUser}>
                            <Label check={values.isAppUser}>
                              <Input
                                type="checkbox"
                                name="isAppUser"
                                onChange={handleChange}
                              />{" "}
                              Combined User Account
                              {/* mark as App User Account */}
                            </Label>
                          </FormGroup>

                          <div className="mt-4">
                            {!loading ? (
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                                onClick={handleSubmit}
                              >
                                Register
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-block "
                                disabled
                              >
                                Loading...
                              </button>
                            )}
                          </div>

                          <div className="mt-4 text-center">
                            <p className="mb-0">
                              By registering you agree to the IGP{" "}
                              <Link to="#" className="text-primary">
                                Terms of Use
                              </Link>
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="mt-5 text-center">
                      <p>
                        Already have an account ?{" "}
                        <Link
                          to="/login"
                          className="font-weight-medium text-primary"
                        >
                          {" "}
                          Login
                        </Link>{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          )
        }}
      </Formik>
    </React.Fragment>
  )
}

export default withRouter(EvenetManager)

EvenetManager.propTypes = {
  history: PropTypes.object,
}
