import React from "react"
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete"
function GooglePlacesAutoComplete(props) {
  const extractAddress = place => {
    const address = {
      street: "",
      streetName: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    }

    if (!Array.isArray(place?.address_components)) {
      return address
    }
    place.address_components.forEach(component => {
      const types = component.types
      const value = component.long_name

      if (types.includes("postal_town")) {
        address.city = value
      }

      if (types.includes("street_number")) {
        address.street = value
      }
      if (types.includes("route")) {
        address.streetName = value
      }

      if (types.includes("administrative_area_level_2")) {
        address.state = value
      }
      if (types.includes("postal_code")) {
        address.postalCode = value
      }

      if (types.includes("country")) {
        address.country = value
      }
    })
    return address
  }

  return (
    <GooglePlacesAutocomplete
      apiKey={process.env.REACT_APP_MAP_KEY}
      selectProps={{
        defaultInputValue: props.preAddress,
        onChange: val => {
          geocodeByAddress(val.label)
            .then(results => {
              const address = extractAddress(results[0])
              props?.setAddress(address)
              return getLatLng(results[0])
            })
            .then(({ lat, lng }) => {
              props?.setLocation({
                latitude: lat,
                longitude: lng,
              })
            })
            .catch(err => {})
        },
      }}
    />
  )
}

export default GooglePlacesAutoComplete
