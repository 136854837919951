import { takeEvery, put, call } from "redux-saga/effects"
import {
  CREATE_ORDER_BY_EVENT_MANAGER,
  GET_ORDERS,
  CREATE_ORDER_BY_VENUE_OWNER,
  GET_ORDER_BY_EVENT_ID,
  GET_ORDER_BY_EVENT_ID_VO,
  GET_ORDERS_STATISTICS,
  CREATE_ORDER_FOR_EVENT_MANAGER,
  CREATE_ORDER_FOR_VENUE_OWNER,
} from "./actionTypes"
import {
  onCreateOrder,
  getMyOrders,
  onGetOrders,
  apiError,
  apiSuccess,
  onGetOrderByEventId,
  onGetOrderByEventIdVO,
  onGetOrdersStatistics,
} from "./actions"
import { db } from "../../config/firebaseConfig"

import {
  collection,
  doc,
  setDoc,
  updateDoc,
  getDocs,
  getDoc,
  query,
  where,
} from "firebase/firestore"
import getAddressFromLngLat from "components/Common/getAddressFromLngLat"
import { getMonth, format } from "date-fns"

const collectionName = "orders"
const colRef = collection(db, collectionName)

function* createOrderByEventManager({ payload: { data, history } }) {
  try {
    const user = JSON.parse(localStorage.getItem("authUser"))
    var id = user.id
    let { booking, event } = data
    const venueId = booking?.venue?.id
    const { date: bookingDate, slot } = booking
    const venueRef = doc(db, "venues", venueId)
    /*   Add booking to Venue */
    const venueData = (yield call(getDoc, venueRef))?.data()

    let bookings = venueData?.bookings || {}
    if (bookings[bookingDate]) {
      // if date is exists
      const arrSlots = bookings[bookingDate]
      const isBooking = arrSlots.find(bookedSlot => bookedSlot == slot.id)
      if (isBooking) {
        throw new Error("Booking aleady exists for this slot")
      }
      bookings[bookingDate] = [...arrSlots, slot.id]
    } else {
      // if date do not exists
      bookings = {
        ...bookings,
        [bookingDate]: [slot.id],
      }
    }
    // update venue after adding new booked slot
    yield call(updateDoc, venueRef, {
      bookings,
    })

    const venueOwnerId = booking.venue.createdBy
    booking.venue = booking.venue.id

    /*  CREATING ORDER */
    const docRef = doc(colRef)
    // creating new order
    const newOrder = {
      booking,
      event,
      createdAt: new Date().toISOString(),
      createdBy: user.id,
      createdTo: venueOwnerId,
      id: docRef.id,
    }
    // creating order in db
    yield call(setDoc, docRef, newOrder)

    // Active Event
    const eventRef = doc(db, "events", event)
    yield call(updateDoc, eventRef, {
      status: "active",
    })
    yield put(apiSuccess("Order Created Successfully"))
    history.push("/events-list")
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* createOrderByVenueOwner({ payload: { data, history } }) {
  try {
    /* 
        em -> event manager    
    */
    const user = JSON.parse(localStorage.getItem("authUser"))
    let { booking, event } = data

    const emId = booking.eventManager.id

    const { date: bookingDate, slot } = booking

    const emRef = doc(db, "managers", emId)
    const emData = (yield call(getDoc, emRef))?.data()

    let bookings = emData?.bookings || {}
    if (bookings[bookingDate]) {
      // if date is exists
      const arrSlots = bookings[bookingDate]
      bookings[bookingDate] = [...arrSlots, slot.id]
    } else {
      // if date do not exists
      bookings = {
        ...bookings,
        [bookingDate]: [slot.id],
      }
    }
    yield call(updateDoc, emRef, {
      bookings,
    })

    booking.eventManager = booking.eventManager.id

    /*  CREATING ORDER */
    const docRef = doc(colRef)
    // creating new order
    const newOrder = {
      booking,
      event,
      createdAt: new Date().toISOString(),
      createdBy: user.id,
      createdTo: booking.eventManager,
      id: docRef.id,
    }

    // creating order in db
    yield call(setDoc, docRef, newOrder)
    // Active Event
    const eventRef = doc(db, "events", event)
    yield call(updateDoc, eventRef, {
      status: "active",
    })
    yield put(apiSuccess("Order Created Successfully"))
    history.push("/events-list")
  } catch (err) {
    yield call(apiError(err.message))
  }
}

export function* createOrderForEventManager({ data, history }) {
  try {
    const user = JSON.parse(localStorage.getItem("authUser"))
    const { event, bookingDate, slot, createdTo } = data

    // check is slot valid
    const emId = createdTo
    const emRef = doc(db, "managers", emId)
    const emData = (yield call(getDoc, emRef))?.data()
    let bookings = emData?.bookings || {}
    let slotsBooking = bookings[bookingDate] || []
    const isBooking = slotsBooking.find(bookedSlot => bookedSlot == slot.id)
    if (isBooking) {
      throw new Error(
        `Sorry! Slot of Event Manager (${emData?.name}) from ${slot.startTime} - ${slot.endTime} already booked`
      )
    }
    yield call(updateDoc, emRef, {
      bookings: {
        ...bookings,
        [bookingDate]: slotsBooking.concat(slot.id),
      },
    })
    // create order
    const docRef = doc(colRef)
    const newOrder = {
      event,
      bookingDate,
      slot,
      createdAt: new Date().toISOString(),
      createdTo,
      createdBy: user.id,
      id: docRef.id,
    }
    yield call(setDoc, docRef, newOrder)
    const eventRef = doc(db, "events", event)
    yield call(updateDoc, eventRef, {
      status: "active",
    })
  } catch (err) {}
}

export function* createOrderForVenueOwner({ data, history }) {
  try {
    const user = JSON.parse(localStorage.getItem("authUser"))
    const { event, bookingDate, slot, venue } = data
    const venueRef = doc(db, "venues", venue)
    const venueData = (yield call(getDoc, venueRef))?.data()
    let bookings = venueData?.bookings || {}
    let slotsBooking = bookings[bookingDate] || []
    const isBooking = slotsBooking.find(bookedSlot => bookedSlot == slot.id)
    if (isBooking) {
      throw new Error(
        `Sorry! Slot of Venue Owner (${venueData?.venueName}) from ${slot.startTime} - ${slot.endTime} already booked`
      )
    }
    yield call(updateDoc, venueRef, {
      bookings: {
        ...bookings,
        [bookingDate]: slotsBooking.concat(slot.id),
      },
    })
    /*  CREATING ORDER */
    const docRef = doc(colRef)
    // creating new order
    const newOrder = {
      event,
      bookingDate,
      slot,
      venue,
      createdAt: new Date().toISOString(),
      createdBy: user.id,
      createdTo: venueData.createdBy,
      id: docRef.id,
    }
    // creating order in db
    yield call(setDoc, docRef, newOrder)
    // Active Event
    const eventRef = doc(db, "events", event)
    yield call(updateDoc, eventRef, {
      status: "active",
    })
  } catch (err) {}
}

//EVENT ORGANIZER
function* getOrderByEventId({ payload: { id } }) {
  try {
    const q = query(colRef, where("event", "==", id))
    const response = (yield call(getDocs, q)).docs?.shift()?.data()
    let { booking } = response
    const venueId = booking.venue
    const venueRef = doc(db, "venues", venueId)
    const venueData = (yield call(getDoc, venueRef))?.data()
    const { latitude, longitude } = venueData.location
    const resolveData = yield getAddressFromLngLat(latitude, longitude)
    const res = {
      ...response,
      venueData: {
        ...venueData,
        address: resolveData,
      },
    }
    yield put(onGetOrderByEventId(res))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getOrderByEventIdVo({ payload: { id } }) {
  try {
    const q = query(colRef, where("event", "==", id))
    const response = (yield call(getDocs, q)).docs
    const resData = response.map(r => {
      return r.data()
    })
    const resolveData = yield Promise.all(
      resData.map(async d => {
        let managerId = d.booking.eventManager
        const docRef = doc(db, "managers", managerId)
        const docData = await getDoc(docRef)
        const managerDetails = docData.data()
        return {
          ...d,
          managerDetails,
        }
      })
    )
    yield put(onGetOrderByEventIdVO(resolveData))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getOrders() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const q = query(colRef, where("createdTo", "==", user.id))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())

    const resolveData = yield Promise.all(
      data.map(async d => {
        const eventID = d.event
        const docRef = doc(db, "events", eventID)
        const docData = await getDoc(docRef)
        const eventDetails = docData.data()
        const createdById = d.createdBy
        const docRefCreatedBy = doc(db, "managers", createdById)
        const createdData = await getDoc(docRefCreatedBy)
        const createdByData = createdData.data()

        let venueID = eventDetails.venue.id
        const docRefVenue = doc(db, "venues", venueID)
        const venueData = await getDoc(docRefVenue)
        const venue = venueData.data()
        const { latitude, longitude } = venue.location
        const address = await getAddressFromLngLat(latitude, longitude)
        venue.address = address

        eventDetails.venue = {
          ...eventDetails.venue,
          ...venue,
        }

        return {
          ...d,
          eventDetails,
          createdByData,
          venue,
        }
      })
    )

    yield put(onGetOrders(resolveData))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getOrdersStatistics() {
  let statistics = {
    activeOrders: 0,
    totalOrders: 0,
    revenue: 0,
    monthlyOrders: 0,
    monthlyRevenue: 0,
    recievedOrdersStats: [],
    recievedOrdersIncome: [],
  }
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const q = query(colRef, where("createdTo", "==", user.id))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())

    statistics.totalOrders = data.length
    statistics.activeOrders = data.filter(
      order => order.status != "completed"
    ).length
    let revenue = 0
    data.forEach(order => {
      if (order.status == "completed") {
        revenue += parseInt(order.slot.price)
      }
    })
    statistics.revenue = revenue

    let orderCounts = Array.from({ length: 12 }, () => 0)
    let incomeCounts = Array.from({ length: 12 }, () => 0)
    const currentYear = new Date().getFullYear()

    for (let orderData of data) {
      if (orderData.createdAt) {
        let orderDate = new Date(orderData.createdAt)
        if (orderDate.getFullYear() === currentYear) {
          let orderMonth = getMonth(orderDate)
          orderCounts[orderMonth]++
          incomeCounts[orderMonth] += parseInt(orderData.slot?.price || 0)
        }
      }
    }
    statistics.recievedOrdersStats = orderCounts
    statistics.recievedOrdersIncome = incomeCounts
    yield put(onGetOrdersStatistics(statistics))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* root() {
  yield takeEvery(CREATE_ORDER_BY_EVENT_MANAGER, createOrderByEventManager)
  yield takeEvery(CREATE_ORDER_FOR_EVENT_MANAGER, createOrderForEventManager)
  yield takeEvery(CREATE_ORDER_FOR_VENUE_OWNER, createOrderForVenueOwner)
  yield takeEvery(CREATE_ORDER_BY_VENUE_OWNER, createOrderByVenueOwner)
  yield takeEvery(GET_ORDER_BY_EVENT_ID, getOrderByEventId)
  yield takeEvery(GET_ORDER_BY_EVENT_ID_VO, getOrderByEventIdVo)
  yield takeEvery(GET_ORDERS, getOrders)
  yield takeEvery(GET_ORDERS_STATISTICS, getOrdersStatistics)
}

export default root
