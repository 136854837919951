import React, { useState } from "react"
import { Col, Row } from "reactstrap"
// imports for card
import {
  CardText,
  Button,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import { deleteVenue, updateVenueStatus } from "../../store/venues/actions"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"

const VenueList = ({ venue }) => {
  const [singlebtn, setSinglebtn] = useState(false)
  const [deletAlert, setDeleteAlert] = useState(false)
  const history = useHistory()
  const [deleteId, setDeleteId] = useState("")
  const dispatch = useDispatch()

  // delete venue handler
  const onDeleteVenue = id => {
    setDeleteId(id)
    setDeleteAlert(true)
  }
  // on delete confirm delete venue
  const onDeleteConfirm = () => {
    dispatch(deleteVenue({ deleteId, history }))
    setDeleteAlert(false)
  }
  // update venue handler
  const updateIsOnline = (id, isOnline) => {
    dispatch(updateVenueStatus({ id: id, isOnline: !isOnline }))
  }

  return (
    <React.Fragment>
      <div className="my-5 mx-md-4">
        <Row>
          <Col sm={12} md={12} lg={5} className="mb-2">
            {deletAlert ? (
              <SweetAlert
                title="Delete Venue!"
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  onDeleteConfirm()
                }}
                onCancel={() => {
                  setDeleteAlert(false)
                }}
              >
                You won&apos;t be able to revert this!
              </SweetAlert>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={12} lg={7} className="mb-md-4">
            <div className="d-flex justify-content-between">
              <Link to={`venue-details/${venue?.id}`}>
                <h1
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {venue?.venueName}
                </h1>
              </Link>
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <i
                  className="mdi mdi-pencil fs-3"
                  style={{ cursor: "pointer", color: "#afafc0" }}
                  onClick={() => setSinglebtn(!singlebtn)}
                />
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => updateIsOnline(venue.id, venue.isOnline)}
                  >
                    {venue?.isOnline ? (
                      <div className="d-flex align-items-center">
                        <span>
                          <i
                            className="mdi mdi-checkbox-blank-circle me-2"
                            style={{
                              color: "red",
                              fontSize: "14px",
                            }}
                          ></i>
                        </span>{" "}
                        Go Offline
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <span>
                          <i
                            className="mdi mdi-checkbox-blank-circle me-2"
                            style={{
                              color: "green",
                              fontSize: "14px",
                            }}
                          ></i>
                        </span>{" "}
                        Go Online
                      </div>
                    )}
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={`edit-venue/${venue.id}`}>
                      <div className="d-flex align-items-center">
                        <span>
                          {" "}
                          <i
                            className="mdi mdi-border-color me-2"
                            style={{
                              color: "green",
                              fontSize: "18px",
                            }}
                          ></i>
                        </span>{" "}
                        Update
                      </div>
                    </Link>
                  </DropdownItem>

                  <DropdownItem onClick={() => onDeleteVenue(venue.id)}>
                    <div className="d-flex align-items-center">
                      <span>
                        <i
                          className="mdi mdi-delete me-2"
                          style={{
                            color: "red",
                            fontSize: "18px",
                          }}
                        ></i>
                      </span>{" "}
                      Delete
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="mb-2">
              <p
                style={{
                  fontSize: "14px",
                }}
              >
                {venue?.address}
              </p>
            </div>
            <div className="my-3 d-flex flex-column flex-md-row">
              {venue?.isOnline ? (
                <Badge
                  className="me-5 fs-6 bg-success"
                  style={{ maxWidth: "60px", maxHeight: "20px" }}
                >
                  Active
                </Badge>
              ) : (
                <Badge
                  className="me-5 fs-6 bg-danger mb-2"
                  style={{ maxWidth: "60px", maxHeight: "20px" }}
                >
                  Offline
                </Badge>
              )}
            </div>
            <div className="fs-6 text-muted me-5 mb-2">
              <span className="fw-bold me-3">Capacity:</span> {venue?.seats}
            </div>
            <CardText>
              {venue.description.substring(0, 300)}
              {" ..."}
            </CardText>

            <Link to={`venue-details/${venue?.id}`}>
              <Button color="primary" className="btn btn-primary ">
                View Details
              </Button>
            </Link>
          </Col>
          <Col sm={12} md={12} lg={5} className="mt-4 mt-md-2 mt-lg-0">
            <img
              className="img-fluid mx-auto d-block"
              style={{
                // display: "block",
                // minWidth: "100%",
                // width: "100%",
                // width: "620px",
                //minHeight: "700px",
                height: "580px",
                borderRadius: "20px",

                borderRadius: "20px",
              }}
              src={venue?.attachments[0]}
              alt="venue"
            />
          </Col>
          <hr className="mt-5" />
        </Row>
      </div>
    </React.Fragment>
  )
}

export default VenueList
