import React, { useState } from "react"
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete"
function GooglePlacesAutoComplete(props) {
  return (
    <GooglePlacesAutocomplete
      apiKey={process.env.REACT_APP_MAP_KEY}
      selectProps={{
        defaultInputValue: props.preAddress,
        onChange: val => {
          geocodeByAddress(val.label)
            .then(results => {
              return getLatLng(results[0])
            })
            .then(({ lat, lng }) => {
              props?.setLocation({
                latitude: lat,
                longitude: lng,
              })
            })
            .catch(err => {})
        },
      }}
    />
  )
}

export default GooglePlacesAutoComplete
