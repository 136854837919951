import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  //
  const manager = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        {manager.role == "eventManager" ? (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Main")} </li>
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i
                    className="mdi mdi-view-dashboard"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t("Content")}</li>
              <li>
                <Link to="/#" className="has-arrow ">
                  <i
                    className="mdi mdi-format-list-bulleted"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Events")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/events-list">{props.t("Events List")}</Link>
                  </li>
                  <li>
                    <Link to="/new-event">{props.t("Add New")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/availability" className="waves-effect">
                  <i
                    className="mdi mdi-clock "
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Availability")}</span>
                </Link>
              </li>
              <li>
                <Link to="/orders" className="waves-effect">
                  <i
                    className="mdi mdi-calendar-check"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Orders")}</span>
                </Link>
              </li>
              <li>
                <Link to="/venue-owners" className="waves-effect">
                  <i className="mdi mdi-home" style={{ fontSize: "20px" }}></i>
                  <span>{props.t("Venues")}</span>
                </Link>
              </li>
              <li>
                <Link to="/announcements" className="waves-effect">
                  <i
                    className="mdi mdi-speaker"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Announcements")}</span>
                </Link>
              </li>
              <li>
                <Link to="/payments" className="waves-effect">
                  <i
                    className="mdi mdi-cash-multiple"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Payments")}</span>
                </Link>
              </li>
            </ul>
          </div>
        ) : manager.role == "venueOwner" ? (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Main")} </li>
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i
                    className="mdi mdi-view-dashboard"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t("Content")}</li>
              <li>
                <Link to="/#" className="has-arrow ">
                  <i
                    className="mdi mdi-format-list-bulleted"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Events")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/events-list">
                      {" "}
                      <i
                        className="mdi mdi-view-list"
                        style={{ fontSize: "20px" }}
                      ></i>
                      <span>{props.t("Events List")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/new-event">
                      {" "}
                      <i
                        className="mdi mdi-grease-pencil"
                        style={{ fontSize: "20px" }}
                      ></i>
                      <span>{props.t("Add Event")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="" className="has-arrow ">
                  <i className="mdi mdi-home" style={{ fontSize: "20px" }}></i>
                  <span>{props.t("Venues")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/venues">
                      <i
                        className="mdi mdi-view-list"
                        style={{ fontSize: "20px" }}
                      ></i>
                      <span>{props.t("Venues List")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/add-venue">
                      {" "}
                      <i
                        className="mdi mdi-grease-pencil"
                        style={{ fontSize: "20px" }}
                      ></i>
                      <span>{props.t("Add Venue")}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/orders" className="waves-effect">
                  <i
                    className="mdi mdi-calendar-check"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Orders")}</span>
                </Link>
              </li>
              <li>
                <Link to="/event-managers" className="waves-effect">
                  <i
                    className="mdi mdi-view-dashboard"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Event Managers")}</span>
                </Link>
              </li>
              <li>
                <Link to="/announcements" className="waves-effect">
                  <i
                    className="mdi mdi-speaker"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Announcements")}</span>
                </Link>
              </li>
              <li>
                <Link to="/payments" className="waves-effect">
                  <i
                    className="mdi mdi-cash-multiple"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Payments")}</span>
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Main")} </li>
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i
                    className="mdi mdi-view-dashboard"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t("Content")}</li>
              <li>
                <Link to="/#" className="has-arrow ">
                  <i
                    className="mdi mdi-format-list-bulleted"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Events")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/organizer-events">
                      {" "}
                      <i
                        className="mdi mdi-view-list"
                        style={{ fontSize: "20px" }}
                      ></i>
                      <span>{props.t("Events List")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/organizer-new-event">
                      {" "}
                      <i
                        className="mdi mdi-grease-pencil"
                        style={{ fontSize: "20px" }}
                      ></i>
                      <span>{props.t("Add Event")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/event-managers" className="waves-effect">
                  <i
                    className="mdi mdi-view-dashboard"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Event Managers")}</span>
                </Link>
              </li>
              <li>
                <Link to="/venue-owners" className="waves-effect">
                  <i className="mdi mdi-home" style={{ fontSize: "20px" }}></i>
                  <span>{props.t("Venues")}</span>
                </Link>
              </li>
              <li>
                <Link to="/announcements" className="waves-effect">
                  <i
                    className="mdi mdi-speaker"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Announcements")}</span>
                </Link>
              </li>
              <li>
                <Link to="/payments" className="waves-effect">
                  <i
                    className="mdi mdi-cash-multiple"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <span>{props.t("Payments")}</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
