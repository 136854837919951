import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  FormGroup,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError } from "../../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useHistory } from "react-router-dom"

// import images
import profileImg from "../../../assets/images/profile-img.png"
import logoImg from "../../../assets/images/logo-light.png"

const VenueOwner = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [passwordType, setPasswordType] = useState("password")

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      name: "",
      password: "",
      number: "",
      img: {},
      status: "pending",
      isAppUser: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      number: Yup.string().required("Please Enter Your Number"),
      name: Yup.string().required("Please Enter Your Name"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: values => {
      const user = {
        ...values,
        role: "venueOwner",
      }
      delete user.isAppUser
      dispatch(
        registerUser({
          user,
          history,
          isAppUser: values.isAppUser,
        })
      )
    },
  })

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(registerUser(values, props.history))
  }

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register Venue Owner</h5>
                        <p>Register account as an venue owner</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Enter Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Profile Image</Label>
                        <Input
                          id="img"
                          name="img"
                          className="form-control"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={e => {
                            validation.handleChange("img", e.target.files[0])
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Number</Label>
                        <Input
                          name="number"
                          type="text"
                          placeholder="Enter Phone Number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.number || ""}
                          invalid={
                            validation.touched.number &&
                            validation.errors.number
                              ? true
                              : false
                          }
                        />
                        {validation.touched.number &&
                        validation.errors.number ? (
                          <FormFeedback type="invalid">
                            {validation.errors.number}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div
                          className="d-flex align-items-center justify-content-between gap-3"
                          style={{
                            border: "1px solid #ced4da",
                            borderRadius: ".25rem",
                            paddingRight: "15px",
                          }}
                        >
                          <Input
                            name="password"
                            type={passwordType}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            style={{
                              border: "none",
                            }}
                            // invalid={
                            //   validation.touched.password &&
                            //   validation.errors.password
                            //     ? true
                            //     : false
                            // }
                          />
                          <div
                            onClick={() => {
                              passwordType == "password"
                                ? setPasswordType("text")
                                : setPasswordType("password")
                            }}
                          >
                            {passwordType == "password" ? (
                              <i className="mdi mdi-eye mx-2 fs-5"></i>
                            ) : (
                              <i className="mdi mdi-eye-off mx-2 fs-5"></i>
                            )}
                          </div>
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <p className="font-size-12 text-danger">
                            {validation.errors.password}
                          </p>
                        ) : // <FormFeedback type="invalid">
                        // {validation.errors.password}
                        // </FormFeedback>
                        null}
                      </div>
                      <FormGroup check={validation.values.isAppUser}>
                        <Label check={validation.values.isAppUser}>
                          <Input
                            type="checkbox"
                            name="isAppUser"
                            onChange={validation.handleChange}
                          />{" "}
                          Combined User Account
                          {/* mark as App User Account */}
                        </Label>
                      </FormGroup>

                      <div className="mt-4">
                        {!loading ? (
                          <button
                            className="btn btn-primary btn-block "
                            type="submit"
                          >
                            Register
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-block "
                            disabled
                          >
                            Loading...
                          </button>
                        )}
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the IGP{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(VenueOwner)

VenueOwner.propTypes = {
  history: PropTypes.object,
}
