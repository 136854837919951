import React, { Suspense, useEffect, useState, lazy } from "react"
import MetaTags from "react-meta-tags"

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

const EventDetails = lazy(() => import("./EventDetails"))
const EventPackages = lazy(() => import("./EventPackages"))
import { updateEvent } from "../../../store/events/actions"
import { useDispatch, useSelector } from "react-redux"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
//
import { useLocation } from "react-router"
import data from "../data"

const EditEvent = props => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [sectionsData, setSectionsData] = useState({})
  //event data
  const [eventData, setEventData] = useState(location.state)

  const { btnLoading } = useSelector(state => ({
    btnLoading: state.Events.btnLoading,
  }))

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function next() {
    toggleTab(activeTab + 1)
  }
  function prev() {
    toggleTab(activeTab - 1)
  }

  // to get and set sections data (event details, event packages page)
  function handleSubmit(data) {
    setSectionsData(prev => ({ ...prev, ...data }))
    if (activeTab !== 3) {
      return next()
    }
    setShowConfirm(true)
  }

  // add event after confirming create event
  function handleSubmitEvent() {
    var newData = {
      ...sectionsData,
      eventType: sectionsData?.eventType?.label,
      eventLevel: sectionsData?.eventLevel?.label,
      requiredSeats: parseInt(sectionsData?.requiredSeats),
    }
    dispatch(updateEvent({ data: newData, history }))
  }
  console.log("eventData", eventData)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Event</title>
      </MetaTags>
      <Container fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h4 className="mb-4">Update Event</h4>
                <div className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}
                      >
                        <NavLink
                          className={classnames({
                            current: activeTab === 1,
                          })}
                          onClick={() => {
                            setactiveTab(1)
                          }}
                          disabled={!(passedSteps || []).includes(1)}
                        >
                          <span className="number fs-4 fw-bold">1</span> Event
                          Details
                        </NavLink>
                      </NavItem>

                      <NavItem
                        className={classnames({ current: activeTab === 2 })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTab === 2,
                          })}
                          onClick={() => {
                            setactiveTab(2)
                          }}
                          disabled={!(passedSteps || []).includes(3)}
                        >
                          <span className="number fs-4 fw-bold">2</span>Packages
                          Pricing
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={classnames({ current: activeTab === 3 })}
                      >
                        <NavLink
                          className={classnames({
                            active: activeTab === 3,
                          })}
                          onClick={() => {
                            setactiveTab(3)
                          }}
                          disabled={!(passedSteps || []).includes(3)}
                        >
                          <span className="number">03</span> Confirm Detail
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  <div className="content clearfix mt-4">
                    <TabContent activeTab={activeTab}>
                      {activeTab == 1 && (
                        <TabPane tabId={1}>
                          {/* Events Details */}
                          <Suspense fallback={<div>Loading...</div>}>
                            <EventDetails
                              handleNext={next}
                              handlePrev={prev}
                              activeTab={activeTab}
                              handleSubmit={handleSubmit}
                              data={eventData}
                            />
                          </Suspense>
                        </TabPane>
                      )}
                      {activeTab == 2 && (
                        <TabPane tabId={2}>
                          <Suspense fallback={<div>Loading...</div>}>
                            <EventPackages
                              handleNext={next}
                              handlePrev={prev}
                              activeTab={activeTab}
                              handleSubmit={handleSubmit}
                              data={eventData?.packages}
                              eventData={sectionsData}
                              requiredSeats={sectionsData?.requiredSeats || 0}
                              packages={eventData?.packages || []}
                            />
                          </Suspense>
                        </TabPane>
                      )}

                      <TabPane tabId={3}>
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Confirm Details</h5>
                                {btnLoading ? (
                                  <button
                                    type="submit"
                                    className="btn btn-primary "
                                    disabled={btnLoading}
                                  >
                                    Updating...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary "
                                    onClick={() => handleSubmitEvent()}
                                  >
                                    Submit
                                  </button>
                                )}
                              </div>
                            </div>
                          </Col>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default EditEvent
