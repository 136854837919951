import React, { useState } from "react"
import { Card, CardBody, CardText, Button, Row, Col } from "reactstrap"
// Rating Plugin
import RatingTooltip from "react-rating-tooltip"
import Slider from "../Slider"
import avatar from "../../../../../assets/images/users/user-1.jpg"
import { Desc } from "./styles/eventManagerDetailsCanvas"

import emptyImage from "assets/images/empty.jpg"

const EventManagerCard = props => {
  let prevAttachments = props.prevAttachments || []
  if (!prevAttachments.length > 0) {
    prevAttachments = [emptyImage]
  }
  return (
    <Card
      style={{ cursor: "pointer" }}
      onClick={() => props.onClickDetails(props.id)}
    >
      <Slider images={prevAttachments} /> <hr className="mt-0" />
      <div className="px-3 pt-2 pb-3">
        <div className="d-flex align-items-center ">
          <img
            className="rounded-circle header-profile-user me-3"
            style={{ objectFit: "cover" }}
            src={props?.img ? props?.img : avatar}
            alt="Avatar"
          />
          <div>
            <p
              className=""
              style={{
                marginBottom: "0",
                fontSize: "16px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              {props.name}
            </p>
            {props.category}
          </div>
        </div>
        <div className="text-dark mt-3">
          <p className="">
            Experience:{" "}
            <span className="text-secondary">
              {props?.experience?.years} {/* {props?.experience?.months} */}
              Year's
            </span>
          </p>
        </div>
        <Desc>
          <CardText style={{ minHeight: "44px" }}>
            {props.bio?.slice(0, 80)} ...
          </CardText>
        </Desc>
        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              className="d-flex align-items-center"
              style={{ color: "#ffbe5b" }}
            >
              <i className="mdi mdi-star h4" />
              <p className="mb-1 me-1" style={{ fontSize: "14px" }}></p>
            </div>
            <p className="mb-1" style={{ fontSize: "14px" }}></p>
          </div>
          <div className="d-flex align-items-center">
            <p className="mb-0"> Starting At</p>
            <span
              className="ms-2 fw-bold text-secondary"
              style={{ fontSize: "18px" }}
            >
              &#163; {props.startingFrom}
            </span>
          </div>
        </div>
        {/* {props.bookBtn && (
          <div className="text-end mt-2">
            <Button size="sm" onClick={() => props.onClickDetails(props.id)}>
              Book Now
            </Button>
          </div>
        )} */}
      </div>
    </Card>
  )
}

export default EventManagerCard
