import React, { useEffect } from "react"
import { Row, Col, Card, Container } from "reactstrap"

const Configurations = props => {
  var user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {}, [user])

  return (
    <div
      className="account-pages mt-sm-5 pb-5"
      style={{ width: "100%", backgroundColor: "#ffffff" }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm={12} md={8} lg={6} xl={6}>
            <Card>
              <Row>
                <Col xs={12}>
                  <div className="py-3 px-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-bold">Configurations</h5>
                    </div>
                    <hr className="mt-0 mb-4" />
                    <div className="d-flex justify-content-between">
                      <p className="text-muted fs-6">Cash Payment</p>

                      {user?.config?.isCashPaymentsAllowed ? (
                        <i
                          className="mdi mdi-check fw-bold text-success font-size-20"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <i
                          className="mdi mdi-cancel text-danger font-size-20"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                    <div className="d-flex  justify-content-between">
                      <p className="text-muted fs-6">Free Events</p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config.noFreeEvents}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted fs-6">Orders Percantage</p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config.ordersPercantage}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted ">Ticket Percentage</p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config.perTicketPercentage}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted fs-6">Platform Fees</p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config.platformFees}
                      </p>
                    </div>
                    <p className="my-4">
                      <ul class="list-group">
                        <li class="list-group-item">
                          <strong>Order Percentage:</strong> Order percentage is
                          the platform fee which will be deducted from the order
                          amount you will receive on this platform. If you get
                          hired as manager or your venue gets booked you will
                          pay this percentage to the platform.
                        </li>

                        <li class="list-group-item">
                          <strong>Platform Fee:</strong> We at IGotPlans team
                          have integrated services like Stripe to make the
                          process good. So this fee is paid to them and it helps
                          us to make services better.
                        </li>

                        <li class="list-group-item">
                          <strong>Featured Event Percentage:</strong> If you get
                          your event featured. This will be the additional
                          percentage on top of platform fee which will be paid
                          to platform.
                        </li>

                        <li class="list-group-item">
                          <strong>Ticket Percentage:</strong> This is the fee
                          which event organisers will pay on per ticket sale to
                          the platform.
                        </li>
                      </ul>
                    </p>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Configurations
