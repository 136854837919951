import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"
import { storage, db, auth, functions } from "../../../config/firebaseConfig"
import { collection, doc, setDoc, getDoc } from "firebase/firestore"
import { createUserWithEmailAndPassword } from "firebase/auth"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"

import { httpsCallable } from "firebase/functions"

import { v4 } from "uuid"
import { uploadFiles, uploadImg } from "helpers/uploadFiles"
import axios from "axios"

const collectionName = "managers"
const collectionRef = collection(db, collectionName)
const configurationsCol = "configurations"
// add image
function* registerUser({ payload: { user, history, isAppUser } }) {
  try {
    const uploadAttachments = yield uploadFiles(
      user?.prevAttachments,
      "eventsImage"
    )
    const uploadImage = yield uploadImg(user.img, "profileImage")
    yield call(createUserWithEmailAndPassword, auth, user.email, user.password)
    // get configuration
    const configRef = doc(db, "configurations", "usersDefaultSettings")
    const cData = yield call(getDoc, configRef)
    const configData = cData.data()
    //
    const userId = yield auth.currentUser.uid
    const docRef = doc(collectionRef, userId)
    const data = {
      ...user,
      config: configData,
      id: docRef.id,
    }
    if (uploadAttachments) {
      data.prevAttachments = uploadAttachments
    }
    if (uploadImage) {
      data.img = uploadImage
    }

    delete data.password
    yield call(setDoc, docRef, data)
    yield put(registerUserSuccessful("Created Successfully"))
    history.push("/login")
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNT_EMAIL_API}?email=${data.email}&&username=${data.name}`
      )
      .then(res => {})
      .catch(err => console.log(err.message))
    if (isAppUser) {
      const paymentConfig = {
        url: "https://igp-payment.herokuapp.com/v1/customer",
        method: "POST",
        data: {
          email: data.email,
          name: data.name,
        },
      }
      const response = yield call(axios, paymentConfig)

      const user = {
        about: null,
        address: null,
        avatar: null,
        calendarEvents: null,
        currentUserId: userId,
        customerId: response.data.customerId,
        dateOfBirth: null,
        email: data.email,
        eventId: [],
        favorite: null,
        managersIds: [],
        name: data.name,
        mobileNumber: data.number,
        notificationToken: null,
        paymentId: null,
        pointsAmount: null,
        registeredFromPlatform: "email",
        status: "active",
        userLevel: [1],
      }
      const collectionRef = collection(db, "users")
      const docRef = doc(collectionRef, userId)
      yield call(setDoc, docRef, user)
    }
  } catch (err) {
    yield put(registerUserFailed(err.message))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
