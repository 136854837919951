import React from "react"
import { Table, Row, Col } from "reactstrap"
import Loader from "components/Common/Loader"

const AvailabilityTable = props => {
  return (
    <Row className="justify-content-center" style={{ minHeight: "70vh" }}>
      <Col xl={12}>
        {props?.loading ? (
          <div className="d-flex align-items-center justify-content-center p-2">
            <Loader />
          </div>
        ) : (
          <div className="table-responsive">
            <h4 className="my-3">Availability</h4>
            <div className="table-responsive">
              <Table className="align-middle table-bordered table-striped table-responsive mb-0">
                <thead className="bg-secondary text-white">
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Day</th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Slot (start-end)</th>
                    <th>Price</th>
                    <th>Valid till</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data?.length > 0
                    ? props?.data?.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.startDate}</td>
                          <td>{item?.day}</td>
                          <td>
                            {item?.slots?.map((slot, i) => {
                              return (
                                <div key={i} className="mb-2">
                                  {slot?.title}
                                  <br />
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            {item?.slots?.map((slot, i) => {
                              return (
                                <div key={i} className="mb-2">
                                  {slot?.type}
                                  <br />
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            {item?.slots?.map((slot, i) => {
                              return (
                                <div key={i} className="mb-1">
                                  {slot.startTime} {" - "}
                                  {slot.endTime}
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            {item?.slots?.map((slot, i) => {
                              return (
                                <div key={i} className="mb-2">
                                  &#163; {slot?.price}
                                  <br />
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            {item?.slots?.map((slot, i) => {
                              return (
                                <div key={i} className="mb-2 ">
                                  {slot?.availableTill}
                                  <br />
                                </div>
                              )
                            })}
                          </td>
                          <td>
                            <div className="d-flex flex-column align-items-center">
                              {item.slots?.map((slot, i) => {
                                return (
                                  <i
                                    key={i}
                                    className="mdi mdi-delete mx-2 font-size-18"
                                    id="edittooltip"
                                    style={{
                                      cursor: "pointer",
                                      color: "#ff5252",
                                    }}
                                    onClick={() => props.remove(index, i)}
                                  />
                                )
                              })}
                            </div>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </div>
            {props.data.length == 0 && (
              <h5 className="mt-4 text-center">No Data Available</h5>
            )}
          </div>
        )}
      </Col>
    </Row>
  )
}

export default AvailabilityTable
