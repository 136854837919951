import React, { useState, useEffect } from "react"
import Dropzone from "react-dropzone"
function DropAttachments({ handleDropFiles }) {
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    handleDropFiles?.(files)
  }
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  return (
    <Dropzone
      onDrop={acceptedFiles => {
        handleAcceptedFiles(acceptedFiles)
      }}
      maxFiles={8}
      accept={[".png", ".gif", ".jpeg", ".jpg"]}
    >
      {({ getRootProps, getInputProps }) => (
        <>
          <div className="dropzone">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-3">
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h4>Drop files here or click to upload.</h4>
            </div>
          </div>
          <div className="text-danger mt-3">Image Dimensions 1200 x 628</div>
          <div className="mt-1">
            Click{" "}
            <a
              href="https://bulkresizephotos.com/en"
              className="text-reset fw-bolder"
              target="_blank"
            >
              here
            </a>{" "}
            to resize image
          </div>
        </>
      )}
    </Dropzone>
  )
}

export default DropAttachments
