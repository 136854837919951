import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Alert,
} from "reactstrap"

import { getEvents } from "../../store/events/actions"
import { getTickets } from "../../store/tickets/actions"
import Error from "components/Common/Error"
import { getDayName, getShowDate } from "common/AddDaysForEvents"
import { COLLECTIONS } from "../../constants/firebase.constants"
import firebaseService from "services/firebaseService"
import Loader from "components/Common/Loader"

import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import * as Yup from "yup"
import { error } from "toastr"

const validationSchema = Yup.object({}).shape({
  event: Yup.object({}).required("Event must required"),
  title: Yup.string().required("Title must required"),
  message: Yup.string().required("Message must required"),
})

function Announcements() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { events, eventsLoading, tickets, ticketsLoading } = useSelector(
    state => ({
      events: state.Events.data,
      eventsLoading: state.Events.loading,
      tickets: state.Tickets.data,
      ticketsLoading: state.Tickets.loading,
    })
  )

  const handleGetTickets = eventId => {
    dispatch(getTickets(eventId))
  }

  const handleSendAnnouncements = async data => {
    setLoading(true)
    const manager = JSON.parse(localStorage.getItem("authUser"))
    console.log(manager.id)
    let users = []
    for (let i = 0; i < tickets.length; i++) {
      users.push(tickets[i].userId)
    }
    try {
      await Promise.all(
        users.map(async user => {
          const resUser = await firebaseService.getDocumentById(
            COLLECTIONS.USERS,
            user
          )
          console.log(resUser)
          if (resUser?.notificationToken) {
            await firebaseService.generatePushNotification(
              resUser?.notificationToken,
              data.title,
              data.message
            )
          }
          await firebaseService.addDocument(COLLECTIONS.NOTIFICATIONS, {
            title: data.title,
            message: data.message,
            subjectType: "event",
            subjectId: data.event.id,
            createdBy: manager.id,
            createdTo: resUser.currentUserId,
          })
        })
      )
      setLoading(false)
      setSuccess("Notifications send successfully")
    } catch (err) {
      setLoading(false)
      console.log(err.message)
    }
  }

  const filteredEvents = events.filter(
    (event, index) => {
      const dateString = event?.eventDate
      const timeString = event?.startTime

      // Combine the date and time strings into a single string
      const dateTimeString = dateString + " " + timeString

      // Create a Date object from the combined string
      const inputDate = new Date(dateTimeString)

      // Get the current date and time
      const currentDate = new Date()
      console.log(event?.eventDate, event?.startTime, inputDate > currentDate)
      return inputDate > currentDate
    }
    // new Date(event.eventDate) > new Date()
  )

  useEffect(() => {
    dispatch(getEvents())
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Container fluid={true}>
        <Col lg={6}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">
                Event Announcements
                <p className="fs-6 text-secondary">
                  (Ticket holders will be notified)*
                </p>
              </CardTitle>
              {success ? (
                <Alert
                  color="success"
                  isOpen={success}
                  toggle={() => setSuccess("")}
                >
                  {success}
                </Alert>
              ) : null}
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  event: "",
                  title: "",
                  message: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  handleSendAnnouncements(values)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  return (
                    <>
                      <div className="mb-3">
                        <Label>Select Event</Label>
                        <Select
                          options={filteredEvents}
                          classNamePrefix="select2-selection"
                          getOptionLabel={option => (
                            <span>
                              {" "}
                              {option?.title?.length > 40
                                ? `${option?.title?.slice(0, 40)}...`
                                : option?.title}
                              {" - "}
                              <span className="">
                                {getShowDate(option?.eventDate)}
                              </span>
                            </span>
                          )}
                          getOptionValue={option => option}
                          onChange={e => {
                            setFieldValue("event", e)
                            handleGetTickets(e.id)
                          }}
                          value={values.event}
                          isLoading={eventsLoading}
                          theme={theme => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              text: "orangered",
                              primary25: "#f0876f",
                              primary: "#f0876f",
                            },
                          })}
                        />
                        {errors.event && touched.event ? (
                          <Error text={errors.event} />
                        ) : null}
                      </div>

                      <div className="d-flex justify-content-between align-items-center mx-2">
                        <p>
                          {values.event && (
                            <p>
                              Ticket Holders :{" "}
                              {ticketsLoading ? (
                                <div
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <span>{tickets.length}</span>
                              )}
                            </p>
                          )}
                        </p>
                        <p></p>
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Title</Label>
                        <Input
                          type="text"
                          name="title"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Your Title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.title && touched.title ? (
                          <Error text={errors.title} />
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">Message</Label>
                        <Input
                          type="text"
                          name="message"
                          className="form-control"
                          id="formrow-firstname-Input"
                          placeholder="Enter Your Message"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.message && touched.message ? (
                          <Error text={errors.message} />
                        ) : null}
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary w-md"
                          onClick={handleSubmit}
                          disabled={
                            eventsLoading || ticketsLoading || !tickets.length
                          }
                        >
                          Send
                        </button>
                      </div>
                    </>
                  )
                }}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </React.Fragment>
  )
}

export default Announcements
