import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import ReactApexChart from "react-apexcharts"

//actions
import { getEarningChartsData } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

function Earning(props) {
  const dispatch = useDispatch()

  const options = {
    chart: {
      toolbar: "false",
    },
    dataLabels: {
      enabled: !1,
    },
    colors: ["#f0876f"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
  }

  const series = [
    {
      name: "Revenue",
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]

  /*
  call api action to receive data
  */

  return (
    <React.Fragment>
      <div id="line-chart" dir="ltr">
        <ReactApexChart
          series={series}
          options={options}
          type="line"
          height={285}
          className="apex-charts"
        />
      </div>
    </React.Fragment>
  )
}

export default Earning
