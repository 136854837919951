import { takeEvery, put, call } from "redux-saga/effects"
import { GET_TICKETS, TICKET_CHECKED_IN, UPDATE_TICKETS } from "./actionTypes"
import { apiSuccess, apiError, onGetTickets } from "./actions"
import { storage, db } from "../../config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  collection,
  getDocs,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore"
import firebaseService from "services/firebaseService"

const collectionName = "tickets"
const colRef = collection(db, collectionName)

function* getTickets({ payload: eventId }) {
  try {
    const q = query(colRef, where("eventId", "==", eventId))

    const response = yield call(getDocs, q)

    const data = response.docs.map(doc => doc.data())
    console.log(data)

    const resolveData = yield Promise.all(
      data.map(async d => {
        const res = await firebaseService.getDocumentById("users", d.userId)
        console.log(res)
        // console.log(d)
        // const userID = d.userId
        // console.log(userID)
        // const docRef = doc(db, "users", userID)
        // console.log(docRef)
        // const docData = await getDoc(docRef)
        // console.log(docData)
        // const userDetails = docData.data()
        // console.log(userDetails)
        // const userName = userDetails?.name
        // console.log(userName)
        return {
          ...d,
          userName: res?.name,
          userId: res?.currentUserId,
        }
      })
    )
    console.log("resolved", resolveData)

    yield put(onGetTickets(resolveData))
  } catch (err) {
    console.log(err)
    yield put(apiError(err.message))
  }
}

function* updateTickets({ payload: data }) {
  try {
    //
    console.log(data)
    const res = yield call(
      firebaseService.updateDocument,
      "tickets",
      data.ticketId,
      data
    )
    console.log(res)
    // const docRef = doc(db, collectionName, data?.ticketId)

    // yield call(updateDoc, docRef, {
    //   ...data,
    // })
    yield put(apiSuccess("Feedback Send Successfully"))
  } catch (error) {
    console.log(error)
    yield put(apiError("Something Went Wrong !"))
  }
}

function* tickedCheckedIn({ payload: tickedId }) {
  try {
    //
    const docRef = doc(db, collectionName, tickedId)
    const ticketData = (yield call(getDoc, docRef))?.data()
    if (ticketData?.isCheckedIn) {
      yield put(apiError("Already Checked In !..."))
    } else {
      yield call(updateDoc, docRef, {
        ...ticketData,
        isCheckedIn: true,
      })
      yield put(apiSuccess("Checked In Successfully !..."))
    }
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* root() {
  yield takeEvery(GET_TICKETS, getTickets)
  yield takeEvery(UPDATE_TICKETS, updateTickets)
  yield takeEvery(TICKET_CHECKED_IN, tickedCheckedIn)
}

export default root
